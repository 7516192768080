@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
}

@font-face {
  font-family: 'Lora';
  src: url('https://fonts.googleapis.com/css?family=Lora&display=swap');
}

@font-face {
  font-family: 'Righteous';
  src: url('https://fonts.googleapis.com/css?family=Righteous&display=swap');
}

@font-face {
  font-family: 'NewKansas';
  src: url('../fonts/new_kansas.woff2') format('woff2'),
       url('../fonts/new_kansas.woff') format('woff');
}